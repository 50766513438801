import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  titleModal: string;
  fileImg: string;
  linkZoom: string;
  constructor(public dom: DomSanitizer) { }

  ngOnInit(): void {
  }
  changed(title: string, link: string): void {
    this.titleModal = title;
    this.fileImg = './assets/images/' + title.replace(/ /g, '').toLocaleLowerCase() + '.jpeg';
    this.linkZoom = link;
  }

}
