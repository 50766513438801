<div class="preloader">
  <div class="loader">
    <div class="ytp-spinner">
      <div class="ytp-spinner-container">
        <div class="ytp-spinner-rotator">
          <div class="ytp-spinner-left">
            <div class="ytp-spinner-circle"></div>
          </div>
          <div class="ytp-spinner-right">
            <div class="ytp-spinner-circle"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--====== HEADER PART START ======-->

<header class="header-area">
  <div class="navbar-area navbar-two" id="nav">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <nav class="navbar navbar-expand-lg">
            <a class="navbar-brand" href="/">
              <img src="assets/images/logo-white.png" alt="Logo" />
            </a>

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTwo"
              aria-controls="navbarTwo" aria-expanded="false" aria-label="Toggle navigation">
              <span class="toggler-icon"></span>
              <span class="toggler-icon"></span>
              <span class="toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse sub-menu-bar" id="navbarTwo">
              <ul class="navbar-nav m-auto">
                <li class="nav-item active">
                  <a class="page-scroll" href="#home">Home</a>
                </li>
                <li class="nav-item ">
                  <a class="page-scroll" href="#about">Acerca de</a>
                </li>
                <li class="nav-item ">
                  <a class="page-scroll" href="#event">Agenda</a>
                </li>
                <li class="nav-item ">
                  <a class="page-scroll" href="#mesas">Mesas Tématicas</a>
                </li>
                <!-- <li class="nav-item">
                  <a class="page-scroll" href="#primary">1° Jornada</a>
                </li>
                <li class="nav-item">
                  <a class="page-scroll" href="#secondary">2° Jornada</a>
                </li> -->
                <li class="nav-item">
                  <a class="page-scroll" href="#team">Expertos</a>
                </li>
              </ul>
            </div>

            <div class="navbar-btn d-none d-sm-inline-block">
              <a class="main-btn-2" href="http://utpl.edu.ec/" target="__blank"><img src="assets/images/utpl.png"
                  width="100px" alt="" srcset=""></a>

            </div>
          </nav>
          <!-- navbar -->
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </div>

  <div id="home" class="header-content-area bg_cover d-flex align-items-center"
    style="background-image: url(assets/images/banner.jpg)">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div data-countdown="2022/12/03 08:30:00"></div>
          <div class="header-content text-center">
            <h2 class="header-title yellow">
              Segunda Jornada de Innovación y Liderazgo Educativo
            </h2>
            <h3 class="sub-title">03 Diciembre, 2022 | 08:30 AM</h3>
            <ul class="header-btn">
              <li>
                <a class="main-btn main-btn-2"
                  href="https://cedia.zoom.us/meeting/register/tZwodeuuqjkqGtOra1J6W7oM8B61evpDJjVF"
                  target="__blank">Registrarme Ahora</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<!--====== HEADER PART ENDS ======-->
<!--====== ABOUT PART START ======-->

<section id="about" class="about-area pb-130 pt-80">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="about-image mt-50">
          <img src="assets/images/banner-1.jpg" alt="About" />
        </div>
        <!-- about image -->
      </div>
      <div class="col-lg-6">
        <div class="about-content mt-45">
          <div class="section-title">
            <h2 class="title">Acerca del evento</h2>
          </div>
          <!-- section title -->

          <p class="text mt-30">
            El programa de maestría en Educación, mención: Innovación y
            Liderazgo Educativo de la UTPL se orienta a que en las instituciones
            educativas se promueva la cultura de la innovación sea en las aulas
            de clase, o en la gestión y liderazgo de la institución, teniendo
            como premisa el desarrollo de los aprendizajes en los estudiantes
            como medio de evidenciar el aporte de una educación integral y con
            visión de comunidad y servicio social.
          </p>
          <p class="text mt-30">
            Por lo que, como parte del módulo de Actores y Gestores del Cambio
            se ha diseñado la oferta de la Segunda Jornada de Innovación y
            Liderazgo Educativo, para crear un encuentro entre todos los
            maestrantes, generando un espacio que permita a cada uno presentar
            sus proyectos de innovación, construyendo un diálogo que potenciará
            los saberes y las competencias de cada maestrante y mejorará la
            calidad de formación de esta Maestría.
          </p>
          <!-- <p class="date"><span>1<sup>era</sup></span> Jornada de Innovación y Liderazgo Educativo</p> -->
        </div>
        <!-- about content -->
      </div>
    </div>
    <!-- row -->
  </div>
  <!-- container -->
</section>

<!--====== ABOUT PART ENDS ======-->

<!--====== COUNTER PART START ======-->

<section id="counter" class="counter-area pt-80 pb-130 ">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-sm-6">
        <div class="single-counter text-center mt-100 ">
          <div class="counter-icon">
            <i class="lni-mic"></i>
          </div>
          <div class="counter-content">
            <span class="count counter">93</span>
            <p class="text">Ponentes (Estudiantes de Maestría)</p>
          </div>
        </div>
        <!-- single counter -->
      </div>
      <div class="col-lg-4 col-sm-6">
        <div class="single-counter text-center mt-100">
          <div class="counter-icon">
            <i class="lni-bulb"></i>
          </div>
          <div class="counter-content">
            <span class="count counter">8</span>
            <p class="text">Mesas Temáticas</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6">
        <div class="single-counter text-center mt-100">
          <div class="counter-icon">
            <i class="lni-search"></i>
          </div>
          <div class="counter-content">
            <span class="count counter">4</span>
            <p class="text">Líneas de Investigación</p>
          </div>
        </div>
        <!-- single counter -->
      </div>
    </div>
    <!-- row -->
  </div>
</section>
<!-- container -->
<section id="event" class="pt-80 pb-130 mx-5">
  <table class="table mx-auto">
    <thead class="thead-dark">
      <tr>
        <th scope="col">Hora</th>
        <th scope="col">Actividad</th>
        <th scope="col">Tiempo</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row">08:35-8:40 </th>
        <td>
          Inicio, presentaci&oacute;n de las Jornadas
        </td>
        <td>
          <p s><span s>05&rsquo;00&rsquo;&rsquo;</span></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><span s>08:40</span></p>
        </td>
        <td>
          <p s><span s>Primera parte</span></p>
          <p s><span s>Intervenci&oacute;n Dra. Mariana Buele, Directora
              de la maestr&iacute;a en Educación, mención: Innovación y
              Liderazgo Educativo,</span><span s>&nbsp;palabras de bienvenida
              e inauguraci&oacute;n de las Segundas Jornadas de Innovaci&oacute;n y Liderazgo Educativo</span></p>
        </td>
        <td>
          <p><span s>20&rsquo;00&rsquo;&rsquo;</span></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><span s>9h00</span></p>
        </td>
        <td s>
          <p s><span s>Conversatorio: Perspectivas de Innovaci&oacute;n y
              Liderazgo Educativo en el Ecuador</span></p>
          <p s><span s>Intervienen:</span></p>
          <p s><span s>Dra. Natalia Lutzak (moderadora)</span></p>
          <p s><span s>Dr. Juan N&uacute;&ntilde;ez Director OTB
              Innova&nbsp;</span></p>
          <p s><span s>Delegado del MINEDUC</span></p>

        </td>
        <td>
          <p><span s>40&rsquo;00&rsquo;&rsquo;</span></p>
          <p><span s>&nbsp;</span></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><span s>9:40</span></p>
        </td>
        <td>
          <p s><span s>Video de comics elaborados por los maestrantes
              sobre reflexiones de c&oacute;mo desarrollar el compromiso &eacute;tico en las instituciones
              educativas</span></p>
        </td>
        <td>
          <p><span s>10&rsquo;00&rsquo;&rsquo;</span></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><span s>9:50</span></p>
        </td>
        <td>
          <p s><span s>RECESO</span></p>
        </td>
        <td>
          <p><span s>10&rsquo;00&rsquo;&rsquo;</span></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><span s>10:00</span></p>
        </td>
        <td>
          <p s><span s>Seguda parte</span></p>
          <p s><span s>Presentaci&oacute;n de la propuestas de
              innovaci&oacute;n la misma que est&aacute; organizada a trav&eacute;s de 8 mesas tem&aacute;ticas en las
              que se socializar&aacute;n 93 propuestas de nuestros maestrantes.</span></p>
          <p s><span s>&nbsp;</span></p>
          <p s><span s>&nbsp;</span></p>
        </td>
        <td>
          <p><span s>90&rsquo;00&rsquo;&rsquo;</span></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><span s>11:30</span></p>
        </td>
        <td>
          <p s><span s>Intervenci&oacute;n de Diana Elizabeth
              Rodr&iacute;guez Espinoza, estudiante de maestr&iacute;a, quien en representaci&oacute;n de sus
              compa&ntilde;eros, nos compartir&aacute; algunas palabras.</span></p>
          <p s><span s>&nbsp;</span></p>
        </td>
        <td>
          <p><span s>10&acute;00</span>
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p><span s>11:40</span></p>
        </td>
        <td>
          <p><span s>Presentaci&oacute;n del E-book de las I Jornadas de
              Innovaci&oacute;n y Liderazgo Educativo</span><span>&nbsp;en el que se presentan los resultados de las
              propuestas de innovaci&oacute;n desarrolladas por los estudiantes de la cohorte abril 2021.</span></p>
          <p s><span s>&nbsp;</span></p>
        </td>
        <td>
          <p><span s>10&rsquo;00</span>
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p><span s>11:50</span></p>
        </td>
        <td>
          <p s><span s>Intervenci&oacute;n de Paola Andrade, palabras de
              cierre.</span></p>
        </td>
        <td s>
          <p><span s>00&acute;10</span>
          </p>
          <p><span s>&nbsp;</span></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><span s>12:00</span></p>
        </td>
        <td>
          <p s><em><span s>Cierre</span></em></p>
        </td>
        <td>
          <p><span s>00&acute;05</span>
          </p>
          <p><span s>&nbsp;</span></p>
        </td>
      </tr>
    </tbody>
  </table>
  <section>

  </section>
  <div class="modal fade bd-example-modal-lg" id="exampleModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered  modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{titleModal}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row justify-content-center">
            <img [src]="fileImg">
            <a [href]="linkZoom" target="_blank" class="main-btn main-btn-2 mt-3">Ingresar a la sala</a>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
  <section id="mesas" class="event-schedule pt-115 pb-130">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="section-title text-center">
            <h2 class="title">MESAS</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="event-tab mt-60">
            <ul class="nav justify-content-between align-items-center" id="myTab" role="tablist">
              <li class="nav-item">
                <a class="active" id="day-1-tab" data-toggle="tab" href="#day-1" role="tab" aria-controls="day-1"
                  aria-selected="true">
                  <h4 class="nav-title">Mesa 1</h4>
                </a>
              </li>

              <li class="nav-item">
                <a id="day-2-tab" data-toggle="tab" href="#day-2" role="tab" aria-controls="day-2"
                  aria-selected="false">
                  <h4 class="nav-title">Mesa 2</h4>
                </a>
              </li>
              <li class="nav-item">
                <a id="day-3-tab" data-toggle="tab" href="#day-3" role="tab" aria-controls="day-3"
                  aria-selected="false">
                  <h4 class="nav-title">Mesa 3</h4>
                </a>
              </li>
              <li class="nav-item">
                <a id="day-4-tab" data-toggle="tab" href="#day-4" role="tab" aria-controls="day-4"
                  aria-selected="false">
                  <h4 class="nav-title">Mesa 4</h4>
                </a>
              </li>
              <li class="nav-item">
                <a id="day-5-tab" data-toggle="tab" href="#day-5" role="tab" aria-controls="day-5"
                  aria-selected="false">
                  <h4 class="nav-title">Mesa 5</h4>
                </a>
              </li>
              <li class="nav-item">
                <a id="day-6-tab" data-toggle="tab" href="#day-6" role="tab" aria-controls="day-6"
                  aria-selected="false">
                  <h4 class="nav-title">Mesa 6</h4>
                </a>
              </li>
              <li class="nav-item">
                <a id="day-7-tab" data-toggle="tab" href="#day-7" role="tab" aria-controls="day-7"
                  aria-selected="false">
                  <h4 class="nav-title">Mesa 7</h4>
                </a>
              </li>
              <li class="nav-item">
                <a id="day-8-tab" data-toggle="tab" href="#day-8" role="tab" aria-controls="day-8"
                  aria-selected="false">
                  <h4 class="nav-title">Mesa 8</h4>
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="day-1" role="tabpanel" aria-labelledby="day-1-tab">
                <div class="event-content pt-40">
                  <div class="single-event d-md-flex mt-30">
                    <div class="event-content media-body">
                      <h4 class="event-title">
                        MAESTRANTES - MESA 1
                      </h4>
                      <ul class="event-meta">
                        <li>CORDOVA CAPA VERONICA DEL CISNE</li>
                        <li>GARCIA LARA YAMILET DE LOS ANGELES</li>
                        <li>GONZALEZ TORRES BERNARDA</li>
                        <li>TENECELA ARMIJOS SALSEDO RODRIGO</li>
                        <li>GUAYANAY CHINCHAY MARIA</li>
                        <li>SANDOVAL CARRION KAREN</li>
                        <li>CASTILLO MERECI EDUARDO JOSE</li>
                        <li>ASTUDILLO LOPEZ RUTH YESENI</li>
                        <li>LUNA BETANCOURT ANGEL RICARD</li>
                        <li>QUEZADA SERRANO MARIA BERTHA</li>
                        <li>PARRA ORTIZ EVELYN YESSENIA</li>
                        <li>VALAREZO AGUILAR YULI ELIZABETH</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="day-2" role="tabpanel" aria-labelledby="day-2-tab">
                <div class="event-content pt-40">
                  <div class="single-event d-md-flex mt-30">
                    <div class="event-content media-body">
                      <h4 class="event-title">
                        MAESTRANTES
                      </h4>
                      <ul class="event-meta">
                        <li>ENCALADA CUEVA ISABEL CRISTINA </li>
                        <li>JIMENEZ JIMENEZ MARIA LILIANA</li>
                        <li>VALAREZO JARAMILLO JESSICA DENIZZE</li>
                        <li>ROMERO CARRION RONAL RIGOBERTO</li>
                        <li>ENRIQUEZ RIOS ERIKA GISELA</li>
                        <li>BALSECA CALUNA DOLORES ISABEL</li>
                        <li>JANETA JANETA ROSA MARIA</li>
                        <li>MARTINEZ VICENTE GENESIS ELIZABETH</li>
                        <li>VILLA GUICHAY ROSA ANA</li>
                        <li>COCHA MENDOZA MICHAELL ESTEFANIA</li>
                        <li>CUENCA QUIÑONEZ JOSE BOLIVAR</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="day-3" role="tabpanel" aria-labelledby="day-3-tab">
                <div class="event-content pt-40">
                  <div class="single-event d-md-flex mt-30">
                    <div class="event-content media-body">
                      <h4 class="event-title">
                        MAESTRANTES
                      </h4>
                      <ul class="event-meta">
                        <li>SARAVIA ROGEL ANDREA GABRIELA </li>
                        <li> CEDEÑO RAMIREZ ANGELICA MARIA </li>
                        <li> NOLE NOLE NANCY CARMEN </li>
                        <li> ROJAS TORRES CHRISTIAN MARTIN </li>
                        <li>TUPIZA YAPO RODOLFO ROLANDO </li>
                        <li>PINZON PEREIRA LEIDY PRISCILA </li>
                        <li>DONOSO CALDERON MARIA DEL CISNE </li>
                        <li> TAPIA BRAVO DHAYSON ESAU </li>
                        <li>CARRION ZUÑIGA LUIS FERNANDO </li>
                        <li>SAMANIEGO MORILLO YADIRA MARIA </li>
                        <li> ASTUDILLO SUQUILANDA DIANA ISABEL </li>
                        <li>IDROVO CABRERA IVAN PATRICIO </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="day-4" role="tabpanel" aria-labelledby="day-4-tab">
                <div class="event-content pt-40">
                  <div class="single-event d-md-flex mt-30">
                    <div class="event-content media-body">
                      <h4 class="event-title">
                        MAESTRANTES
                      </h4>
                      <ul class="event-meta">
                        <li>MENDOZA VIVANCO FLOR SUSANA</li>
                        <li>ERREYES ARMIJOS MARTHA ENIDH </li>
                        <li>SANTIN LUNA GINA JOVITA </li>
                        <li>MARTHA ENIDH ERREYES ARMIJOS </li>
                        <li> QUEVEDO DELGADO ELIZABETH MERCEDES </li>
                        <li>GUAMAN MOROCHO WILMER MEDARDO </li>
                        <li> BRAVO GUAMO SARA MAFALDA </li>
                        <li>RIVERA MILES CARMEN ELIZABETH </li>
                        <li> MENDOZA VIVANCO YURI GINA</li>
                        <li>GARATE RUGEL DAYANNA CAROLINA </li>
                        <li>SACTA TENEMAZA FRANCISCO SANTIAGO</li>
                        <li>GOMEZ CORDOVA CLAUDIA ALEXANDRA </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="day-5" role="tabpanel" aria-labelledby="day-5-tab">
                <div class="event-content pt-40">
                  <div class="single-event d-md-flex mt-30">
                    <div class="event-content media-body">
                      <h4 class="event-title">
                        MAESTRANTES
                      </h4>
                      <ul class="event-meta">
                        <li> CALVA GOMEZ EVA MIKAELA</li>
                        <li>VELAZQUEZ CORDOVA JESSICA TATIANA </li>
                        <li>BAQUE ROMERO GUSTAVO JAVIER </li>
                        <li>SALAS SANCHEZ OLGA NOHEMI</li>
                        <li>QUITO ICHAN ELVIA ALICIA </li>
                        <li>RAMOS REYES DORYS JANETH </li>
                        <li>LLANOS FABARA RUBEN DARIO </li>
                        <li>CABRERA GUZMAN CRISTIAN MIGUEL</li>
                        <li>CATOTA PALLASCO TANIA MARIBEL </li>
                        <li>RUIZ CALO CARLOS VINICIO </li>
                        <li> ABAD PEÑA ANA LUCIA </li>
                        <li> PUENTE CALLE MARTIN ALEJANDRO </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="day-6" role="tabpanel" aria-labelledby="day-6-tab">
                <div class="event-content pt-40">
                  <div class="single-event d-md-flex mt-30">
                    <div class="event-content media-body">
                      <h4 class="event-title">
                        MAESTRANTES
                      </h4>
                      <ul class="event-meta">
                        <li>MUÑOZ GARCES VERONICA ANTONIETA </li>
                        <li> COBA CASTILLO NELLY JACQUELINE </li>
                        <li>RODRIGUEZ ESPINOSA DIANA </li>
                        <li> CORDOVA GRANDA NIMIA ESPERANZA </li>
                        <li>AREVALO NIEBLA MIRELLA DE JESUS </li>
                        <li>ALCIVAR LOOR KAREN SIBONEY </li>
                        <li>GALVEZ BARRAGAN JOHN DAVID </li>
                        <li>ANCHUNDIA ZAMBRANO LUIS ALEXANDER </li>
                        <li>ARTIEDA ORTIZ JESSICA CAROLINA</li>
                        <li> MUÑOZ TIPANTIZA EDISON FABIAN </li>
                        <li>ESTRELLA PONCE FABRICIO RICARDO </li>
                        <li>NARANJO SANDOVAL ESTEBAN DANIEL </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="day-7" role="tabpanel" aria-labelledby="day-7-tab">
                <div class="event-content pt-40">
                  <div class="single-event d-md-flex mt-30">
                    <div class="event-content media-body">
                      <h4 class="event-title">
                        MAESTRANTES
                      </h4>
                      <ul class="event-meta">
                        <li>REYES VALLADARES VICENTE MANUEL </li>
                        <li>MALDONADO CALDERON CARLOS STALIN </li>
                        <li>VERA OVIEDO YADIRA NATIVIDAD </li>
                        <li>CHARIG GUALLPA VICENTE EDUARDO </li>
                        <li>NEGRETE IBARRA GINA ELIZABETH </li>
                        <li>TIPAN CHIPUGSI VERONICA NATALY </li>
                        <li>MERINO LUZON DORIS MARIELA </li>
                        <li>FUENMAYOR INSUASTI VIVIANA PAOLA </li>
                        <li> CHASIPANTA CHUQUIMARCA HECTOR ANIBAL </li>
                        <li>MENDEZ MINA MERY ROCIO </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="day-8" role="tabpanel" aria-labelledby="day-8-tab">
                <div class="event-content pt-40">
                  <div class="single-event d-md-flex mt-30">
                    <div class="event-content media-body">
                      <h4 class="event-title">
                        MAESTRANTES
                      </h4>
                      <ul class="event-meta">
                        <li>CARDENAS ROGEL JUAN CARLOS </li>
                        <li>APOLO ROMERO PRISCILA PATRICIA </li>
                        <li>AGUILAR FEIJOO LUZ MARIA</li>
                        <li>CHAMORRO VALLEJO JEMINA DE LOS ANGELES </li>
                        <li>MORALES ANDRANGO EDWIN ROLANDO </li>
                        <li>POLO VALDIVIESO GIULIANA BEATRIZ </li>
                        <li>BERNAL SARMIENTO MIRIAM EUGENIA</li>
                        <li>VERA SALAZAR ALEXANDRA ANDREA </li>
                        <li>VILLACIS VALLE LIZBETH ESTEFANIA</li>
                        <li> CAMPOVERDE FLORES CLAUDIA JOHANA </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- <section id="secondary" class="event-schedule pt-115 pb-130">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="section-title text-center">
          <h2 class="title">AGENDA - SEGUNDA JORNADA</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="event-tab mt-60">
          <ul class="nav justify-content-between align-items-center" id="myTab" role="tablist">
            <li class="nav-item">
              <a class="active" id="day-8-tab" data-toggle="tab" href="#day-8" role="tab" aria-controls="day-8"
                aria-selected="true">
                <h4 class="nav-title">Mesa 8</h4>
              </a>
            </li>

            <li class="nav-item">
              <a id="day-9-tab" data-toggle="tab" href="#day-9" role="tab" aria-controls="day-9" aria-selected="false">
                <h4 class="nav-title">Mesa 9</h4>
              </a>
            </li>
            <li class="nav-item">
              <a id="day-10-tab" data-toggle="tab" href="#day-10" role="tab" aria-controls="day-10"
                aria-selected="false">
                <h4 class="nav-title">Mesa 10</h4>
              </a>
            </li>
            <li class="nav-item">
              <a id="day-11-tab" data-toggle="tab" href="#day-11" role="tab" aria-controls="day-11"
                aria-selected="false">
                <h4 class="nav-title">Mesa 11</h4>
              </a>
            </li>
            <li class="nav-item">
              <a id="day-12-tab" data-toggle="tab" href="#day-12" role="tab" aria-controls="day-12"
                aria-selected="false">
                <h4 class="nav-title">Mesa 12</h4>
              </a>
            </li>
            <li class="nav-item">
              <a id="day-13-tab" data-toggle="tab" href="#day-13" role="tab" aria-controls="day-13"
                aria-selected="false">
                <h4 class="nav-title">Mesa 13</h4>
              </a>
            </li>
            <li class="nav-item">
              <a id="day-14-tab" data-toggle="tab" href="#day-14" role="tab" aria-controls="day-14"
                aria-selected="false">
                <h4 class="nav-title">Mesa 14</h4>
              </a>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="day-8" role="tabpanel" aria-labelledby="day-8-tab">
              <div class="event-content pt-40">
                <div class="single-event d-md-flex mt-30">
                  <div class="event-content media-body">
                    <h4 class="event-title">
                      Tendencias en innovación educativa: innovación en las
                      metodologías de enseñanza en la docencia
                    </h4>
                    <ul class="event-meta">
                      <li>Inicia: 11:30 AM</li>
                      <li>Finaliza: 12:30 AM</li>
                      <li>
                        <button class="main-btn" target="_blank" data-toggle="modal" data-target="#exampleModal"
                          (click)="changed('Mesa 8','https://utpl.zoom.us/j/8385545380' )">Ir a la
                          mesa</button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="day-9" role="tabpanel" aria-labelledby="day-9-tab">
              <div class="event-content pt-40">
                <div class="single-event d-md-flex mt-30">
                  <div class="event-content media-body">
                    <h4 class="event-title">
                      Tecnologías como herramientas para la Innovación
                      Educativa: Usos de diversas herramientas
                    </h4>
                    <ul class="event-meta">
                      <li>Inicia: 11:30 AM</li>
                      <li>Finaliza: 12:30 AM</li>
                      <li>
                        <button class="main-btn" target="_blank" data-toggle="modal" data-target="#exampleModal"
                          (click)="changed('Mesa 9','https://utpl.zoom.us/j/2887420361' )">Ir a la
                          mesa</button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="day-10" role="tabpanel" aria-labelledby="day-10-tab">
              <div class="event-content pt-40">
                <div class="single-event d-md-flex mt-30">
                  <div class="event-content media-body">
                    <h4 class="event-title">
                      Tecnologías como herramientas para la Innovación Educativa
                      en la asignatura de matemáticas
                    </h4>

                    <ul class="event-meta">
                      <li>Inicia: 11:30 AM</li>
                      <li>Finaliza: 12:30 AM</li>
                      <li>
                        <button class="main-btn" target="_blank" data-toggle="modal" data-target="#exampleModal"
                          (click)="changed('Mesa 10','https://utpl.zoom.us/j/87430742010' )">Ir a la
                          mesa</button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="day-11" role="tabpanel" aria-labelledby="day-11-tab">
              <div class="event-content pt-40">
                <div class="single-event d-md-flex mt-30">
                  <div class="event-content media-body">
                    <h4 class="event-title">
                      Tecnologías como herramientas para la Innovación Educativa
                      aplicadas en diferentes asignaturas
                    </h4>
                    <ul class="event-meta">
                      <li>Inicia: 11:30 AM</li>
                      <li>Finaliza: 12:30 AM</li>
                      <li>
                        <button class="main-btn" target="_blank" data-toggle="modal" data-target="#exampleModal"
                          (click)="changed('Mesa 11','https://utpl.zoom.us/j/86573665165' )">Ir a la
                          mesa</button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="day-12" role="tabpanel" aria-labelledby="day-12-tab">
              <div class="event-content pt-40">
                <div class="single-event d-md-flex mt-30">
                  <div class="event-content media-body">
                    <h4 class="event-title">
                      Desafíos, motivación y valores para el desarrollo del
                      aprendizaje del siglo XXI
                    </h4>
                    <ul class="event-meta">
                      <li>Inicia: 11:30 AM</li>
                      <li>Finaliza: 12:30 AM</li>
                      <li>
                        <button class="main-btn" target="_blank" data-toggle="modal" data-target="#exampleModal"
                          (click)="changed('Mesa 12','https://utpl.zoom.us/j/81529952828' )">Ir a la
                          mesa</button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="day-13" role="tabpanel" aria-labelledby="day-13-tab">
              <div class="event-content pt-40">
                <div class="single-event d-md-flex mt-30">
                  <div class="event-content media-body">
                    <h4 class="event-title">
                      La función del docente en un contexto de nuevos retos:
                      competencias, formación, liderazgo, desarrollo personal y
                      profesional
                    </h4>
                    <ul class="event-meta">
                      <li>Inicia: 11:30 AM</li>
                      <li>Finaliza: 12:30 AM</li>
                      <li>
                        <button class="main-btn" target="_blank" data-toggle="modal" data-target="#exampleModal"
                          (click)="changed('Mesa 13','https://utpl.zoom.us/j/89529600306' )">Ir a la
                          mesa</button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="day-14" role="tabpanel" aria-labelledby="day-14-tab">
              <div class="event-content pt-40">
                <div class="single-event d-md-flex mt-30">
                  <div class="event-content media-body">
                    <h4 class="event-title">
                      Redes sociales y su uso en el aprendizaje: WhatsApp,
                      facebook y otros
                    </h4>
                    <ul class="event-meta">
                      <li>Inicia: 11:30 AM</li>
                      <li>Finaliza: 12:30 AM</li>
                      <li>
                        <button class="main-btn" target="_blank" data-toggle="modal" data-target="#exampleModal"
                          (click)="changed('Mesa 14','https://utpl.zoom.us/j/7976181179' )">Ir a la
                          mesa</button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

  <section id="features" class="features-area pt-115 pb-130">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="section-title text-center pb-20">
            <h2 class="title">Propósito y objetivos</h2>
            <p class="text">
              La Jornada tiene como propósito evidenciar y compartir los proyectos
              y propuestas desarrolladas por los maestrantes en directa relación
              con los objetivos del programa de maestría.
            </p>
          </div>
          <!-- section title -->
        </div>
      </div>
      <!-- row -->
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 col-sm-8">
          <div class="single-features text-center mt-30">
            <div class="features-icon">
              <i class="lni lni-search"></i>
              <span>01</span>
            </div>
            <div class="features-content">
              <!-- <h4 class="features-title"><a href="#">Great Speakers</a></h4> -->
              <p class="text">
                Identificar necesidades del contexto educativo que demanden
                innovación en el ámbito de la gestión del aula, la organización
                escolar y/o contextos no formales de aprendizaje.
              </p>
            </div>
          </div>
          <!-- single features -->
        </div>
        <div class="col-lg-4 col-md-6 col-sm-8">
          <div class="single-features text-center mt-30 ">
            <div class="features-icon">
              <i class="lni lni-bulb"></i>
              <span>02</span>
            </div>
            <div class="features-content">
              <p class="text">
                Diseñar las propuestas de innovación que permitan atender las
                necesidades identificadas en el análisis del contexto educativo.
              </p>
            </div>
          </div>
          <!-- single features -->
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </section>

  <!--====== FEATURES PART ENDS ======-->

  <!--====== TEAM PART START ======-->

  <section id="team" class="team-area pt-115 pb-130">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="section-title text-center pb-20">
            <h2 class="title">Nuestros Expertos</h2>
            <!-- <p class="text">Lorem ipsum dolor sit amet, in quodsi vulputate pro. Ius illum vocent mediocritatem reiciendis
            odit sed, vero amet blanditiis cule dicta iriure at phaedrum.</p> -->
          </div>
          <!-- section title -->
        </div>
      </div>
      <!-- row -->

      <div class="row">
        <div class="col-lg-4 col-sm-6">
          <div class="single-team text-center mt-30">
            <div class="team-image">
              <img src="assets/images/mad_utpl.png" alt="Team" />
            </div>
            <div class="team-content">
              <div class="team-social">
                <ul class="social"></ul>
              </div>
              <h4 class="team-name"><a>Dra. Natalia Vladimirovna Lutsak Yaroslova
                </a></h4>
              <span class="sub-title">Directora de Postgrados
              </span>
              <br />
              <span class="sub-title">UTPL</span>
            </div>
          </div>
          <!-- single team -->
        </div>
        <!--  <div class="col-lg-4 col-sm-6">
          <div class="single-team text-center mt-30">
            <div class="team-image">
              <img src="assets/images/rosarito.png" alt="Team" />
            </div>
            <div class="team-content">
              <div class="team-social">
                <ul class="social">
                  <li>
                    <a href="https://twitter.com/mrrivasmanzano" target="_blank"><i
                        class="lni-twitter-original"></i></a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/in/rosario-rivas-90981a38/" target="_blank"><i
                        class="lni-linkedin-original"></i></a>
                  </li>
                </ul>
              </div>
              <h4 class="team-name"><a>Dra. Rosario de Rivas Manzano</a></h4>
              <span class="sub-title">Vicerrectora Académica</span>
              <br />
              <span class="sub-title">UTPL</span>
            </div>
          </div>
        </div> -->
        <div class="col-lg-4 col-sm-6">
          <div class="single-team text-center mt-30">
            <div class="team-image">
              <img src="assets/images/juan.png" alt="Team" />
            </div>
            <div class="team-content">
              <div class="team-social">
                <ul class="social">
                  <li>
                    <a href="https://twitter.com/juannunezc" target="_blank"><i class="lni-twitter-original"></i></a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/in/juannunezc/" target="_blank"><i
                        class="lni-linkedin-original"></i></a>
                  </li>
                </ul>
              </div>
              <h4 class="team-name"><a>Dr. Juan Núñez</a></h4>
              <span class="sub-title">Socio-Director en OTBInnova</span>
              <br />
              <span class="sub-title">Universidad de Comillas</span>
            </div>
          </div>
          <!-- single team -->
        </div>
        <!--  <div class="col-lg-4 col-sm-6">
        <div class="single-team text-center mt-30">
          <div class="team-image">
            <img src="assets/images/Ramon_Ferreiro.jpeg" alt="Team" />
          </div>
          <div class="team-content">
            <div class="team-social">
              <ul class="social">
                <li>
                  <a href="assets/images/Ramon_Ferreiro.jpeg" target="_blank"><i class="lni-linkedin-original"></i></a>
                </li>
              </ul>
            </div>
            <h4 class="team-name"><a>Dr. Ramón Ferreiro</a></h4>
            <span class="sub-title">Docente Nova</span>
            <br />
            <span class="sub-title">Southeastern University</span>
          </div>
        </div>
      </div> -->
        <div class="col-lg-4 col-sm-6">
          <div class="single-team text-center mt-30">
            <div class="team-image">
              <img src="https://pbs.twimg.com/profile_images/970750273630146560/T0AgO9lr_400x400.jpg" alt="Team" />
            </div>
            <div class="team-content">
              <!-- <div class="team-social">
              <ul class="social">
                <li>
                  <a href="https://ec.linkedin.com/in/diana-castellanos-vela-02048671" target="_blank"><i
                      class="lni-linkedin-original"></i></a>
                </li>
              </ul>
            </div> -->
              <!--   <h4 class="team-name"><a>Diana Castellanos Vela </a></h4> -->
              <span class="sub-title">Subsecretaria para la Innovación Educativa y el Buen Vivir en Ministerio de
                Educación</span>
            </div>
          </div>
          <!-- single team -->
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </section>
  <!--====== CLIENT PART START ======-->

  <div id="client" class="client-area pt-115 pb-130 gray-bg">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="section-title text-center pb-50">
            <h2 class="title">Una iniciativa de</h2>
          </div>
          <!-- section title -->
        </div>
      </div>
      <!-- row -->
      <div class="row client-active align-items-center m-auto">
        <div class="col-lg-6">
          <div class="single-client">
            <img src="assets/images/LiiD.png" alt="Client" width="100%" height="150" />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="single-client">
            <img src="https://utpl.edu.ec/sites/default/files/archivos/marca%20UTPL%202018-03.png" alt="UTPL"
              width="100%" />
          </div>
        </div>
      </div>

      <!-- row -->
    </div>
    <!-- container -->
  </div>

  <!--====== CLIENT PART ENDS ======-->
  <!--====== TEAM PART ENDS ======-->
  <!--====== FOOTER PART START ======-->
  <section id="footer" class="footer-area ">
    <div class="footer-copyright ">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="copyright text-center">
              <p class="text yellow">
                Adaptado por
                <a href="https://innovaciondocente.utpl.edu.ec/" rel="nofollow" target="_blank" class="yellow">LiiD
                  UTPL</a>. All Rights
                Reserved by
                <a href="https://graygrids.com/" rel="nofollow" target="_blank" class="yellow">GrayGrids</a>
              </p>
            </div>
            <!-- copyright -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </div>
    <!-- container -->
  </section>

  <!--====== FOOTER PART ENDS ======-->

  <!--====== BACK TOP TOP PART START ======-->

  <a href="#" class="back-to-top"><i class="lni-chevron-up"></i></a>
